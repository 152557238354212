import React, { useMemo } from 'react'
import { ContainerProps, Skeleton, ProgressiveImage, Container, Link } from '@thirstycamel/ui'
import useMedia from 'use-media'
import { gql } from 'graphql.macro'

import { useStore } from '../../store/hooks'
import { useAdvertsQuery, Advert as WPAdvert } from '../../../generated'
import imageProxy from '../../utils/imageProxy'
import dayjs from 'dayjs'

const ADVERTS_FETCH_LIMIT = 15 // Different to the max number of banners shown on the site
const ADVERTS_SHOWING_LIMIT = 10

const handleFilter = (advert: { node: WPAdvert }) => {
  return !advert?.node?.acf?.endsAt || dayjs(advert.node.acf.endsAt).isAfter(Date.now())
}

gql`
  query Adverts($first: Int, $region: String, $position: String) {
    adverts(first: $first, where: { region: $region, position: $position }) {
      edges {
        node {
          title
          acf {
            link
            endsAt
            imageDesktop {
              sourceUrl
            }
            imageMobile {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

export interface AdvertProps extends Omit<ContainerProps, 'position'> {
  position: 'HOME_TOP' | 'HOME_MIDDLE' | 'HOME_BOTTOM' | 'PRODUCT'
}

export const Advert = ({ position, ...restProps }: AdvertProps) => {
  const isMobile = useMedia({ maxWidth: '30em' })
  const location = useStore(s => s.location)
  const selectedStore = useStore(s => s.store.selectedStore)

  const region = selectedStore?.region || location.region || 'VIC'

  const [query] = useAdvertsQuery({
    variables: { first: ADVERTS_FETCH_LIMIT, region, position },
  })

  const adverts = useMemo(
    () => query.data?.adverts?.edges?.filter(handleFilter).slice(0, ADVERTS_SHOWING_LIMIT),
    [query.data],
  )

  if (!adverts?.length) {
    if (query.fetching) {
      return (
        <Container {...restProps}>
          <Skeleton paddingBottom={['calc(1 / 5 * 100%)', , 'calc(1 / 8 * 100%)']} />
        </Container>
      )
    }

    return null
  }

  const randomIndex = Math.floor(Math.random() * adverts.length)
  const advert = adverts[randomIndex]?.node

  /* Determine whether to use the desktop or mobile image for the banners. */
  const imageKey = isMobile ? 'imageMobile' : 'imageDesktop'

  const image = (
    <ProgressiveImage
      display="block"
      src={imageProxy(advert?.acf[imageKey]?.sourceUrl, { wp: true })}
      size="cover"
      backgroundPosition="center"
      w="100%"
      h="100%"
      paddingBottom={['calc(1 / 5 * 100%)', , 'calc(1 / 8 * 100%)']}
      border="1px"
      borderColor="gray.200"
    />
  )

  return (
    <Container px={[4, 5, 6, 8]} {...restProps}>
      {advert?.acf.link ? <Link href={advert?.acf.link}>{image}</Link> : image}
    </Container>
  )
}

export default Advert
