import React, { useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Flex,
  Carousel,
  Container,
  LinkButton,
  BoxProps,
  useProductsQuery,
  Heading,
  CarouselButton,
  CarouselButtonNew,
} from '@thirstycamel/ui'
import { useMediaQuery } from 'react-responsive'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import range from 'lodash.range'

import ProductCard, { ProductCardPlaceholder } from '../ProductCard'
import { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import { useStore } from '../../store/hooks'

export interface SpecialsSectionProps extends BoxProps {
  title: string
  slug: string
}

export const SpecialsSection = ({ title, slug, ...restProps }: SpecialsSectionProps) => {
  const selectedStore = useStore(store => store.store.selectedStore)
  const location = useStore(store => store.location)

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })
  const isTablet = useMediaQuery({ query: '(max-width: 768px)' })
  const isSmallDesktop = useMediaQuery({ query: '(max-width: 980px)' })
  const isMediumDesktop = useMediaQuery({ query: '(max-width: 1280px)' })

  let slidesPerPage = 5

  if (isMediumDesktop) {
    slidesPerPage = 4
  }
  if (isSmallDesktop) {
    slidesPerPage = 3
  }
  if (isTablet) {
    slidesPerPage = 2.5
  }
  if (isMobile) {
    slidesPerPage = 1.5
  }

  let productOptions: any = {
    group: slug,
  }

  if (!selectedStore) {
    productOptions.region = location.region
  } else if (selectedStore) {
    productOptions.store = selectedStore.slug
  }

  const query = useProductsQuery<CoreProductEntity>(productOptions, {
    staleTime: 30000,
  })

  const isFetching = query.isFetching
  const items = query.resolvedData?.results.items

  /* Show nothing when there are no specials found. */
  if (!isFetching && !items?.length) return null

  return (
    <Box {...restProps}>
      <Box maxWidth="1480px" mx="auto" w="100%" px={[2, 2, '1rem']}>
        <Stack spacing={8}>
          <Flex justify="center" align="center">
            <Box position="relative" w={['100%', 'auto']}>
              <Heading
                textTransform="uppercase"
                ml={[3, 0]}
                fontSize={['3xl', '3xl']}
                textAlign={['left', 'center']}
              >
                {title}
              </Heading>

              <Box
                position="absolute"
                top="50%"
                transform="translateY(-50%)"
                right={[0, 'auto']}
                left={[, 'calc(100% + 1rem)']}
                pr={0}
              >
                <LinkButton
                  href="/[group]"
                  hrefAs={`/${slug}`}
                  variant="unstyled"
                  fontFamily="heading"
                  color="pink.500"
                  textTransform="uppercase"
                  px={3}
                  py={2}
                >
                  View all
                </LinkButton>
              </Box>
            </Box>
          </Flex>

          <div>
            <CarouselProvider
              key={slidesPerPage}
              visibleSlides={isFetching ? 1 : slidesPerPage}
              naturalSlideWidth={250}
              naturalSlideHeight={400}
              isIntrinsicHeight
              totalSlides={isFetching ? 5 : items?.length}
              step={Math.floor(slidesPerPage)}
              infinite
            >
              <Box width="100%" position="relative">
                <Flex>
                  <ButtonBack>
                    <CarouselButtonNew side="left" />
                  </ButtonBack>

                  <Box width="100%" px={[null, null, '2rem']}>
                    <Slider>
                      {isFetching ? (
                        <Slide index={0}>
                          <Flex>
                            {range(Math.ceil(slidesPerPage)).map((_, index) => (
                              <Box px={2} flexGrow={1} minWidth="200px">
                                <ProductCardPlaceholder w="100%" />
                              </Box>
                            ))}
                          </Flex>
                        </Slide>
                      ) : (
                        items?.map((product, index) => (
                          <Slide index={index}>
                            <Box
                              key={`${product?.slug}-1`}
                              w="100%"
                              h="100%"
                              px={2}
                              minWidth="200px"
                            >
                              <ProductCard product={product} h="100%" />
                            </Box>
                          </Slide>
                        ))
                      )}
                    </Slider>
                  </Box>

                  <ButtonNext>
                    <CarouselButtonNew side="right" />
                  </ButtonNext>
                </Flex>
              </Box>
            </CarouselProvider>
          </div>
        </Stack>
      </Box>
    </Box>
  )
}

export default SpecialsSection
