import { parseCookies } from 'nookies'

export const useApp = () => {
  const { IS_MOBILE_APP, PUSH_TOKEN, APP_PLATFORM } = parseCookies()

  return {
    isMobileApp: IS_MOBILE_APP,
    platform: APP_PLATFORM,
    pushToken: PUSH_TOKEN,
  }
}
